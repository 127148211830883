import { Button, Modal } from 'antd';
import ButtonWithLoading from 'components/buttonWithLoading';
import React, { useState } from 'react';
import { handleErrorFromServer } from 'utils/errors';

const ModalDelete = ({ url = null, id = null, isLoading, onCancel, children, onClickConfirm, onFinishDelete, ...rest }) => {
  const [isSendingData, setIsSendingData] = useState(false)
  const handleClickConfirm = async () => {
    if (url && id) {
      try {
        setIsSendingData(() => true)
        // const res = await ScenarioService.deleteItem(id, url)
        // handleSuccessFromServer(res)
        setIsSendingData(() => false)
        onCancel()
        onFinishDelete(url, id)
      } catch (error) {
        setIsSendingData(() => false)
        handleErrorFromServer(error)
      }
    } else onClickConfirm()
  }
  return (
    <Modal footer={null} onCancel={onCancel} className="delete-modal" {...rest}>
      <div className='delete-modal__content'>
        {children || <span>本当に削除しますか。</span>}
      </div>
      <div className='delete-modal__footer'>
        <Button onClick={onCancel}>キャンセル</Button>
        <ButtonWithLoading
          onClick={handleClickConfirm}
          type="primary"
          isLoading={isLoading || isSendingData}
        >削除</ButtonWithLoading>
      </div>
    </Modal>
  )
}

export default ModalDelete;
