import React, { useEffect } from "react";
import { Table } from "antd";
// import Flag from "assets/icons/flag.svg";

const mentalTestData = [
	"痩せている",
	"がっちり太っている",
	"ぽっちゃり太っている",
	"のどがよく渇く",
	"上半身がむくみやすい",
	"下半身がむくみやすい",
	"体が全体が重く感じる",
	"体の芯まで冷える",
	"血圧が高い",
	"血圧が低い",
	"腰に疲れを感じる",
	"筋肉がつりやすい",
	"肩がこる",
	"よく眠れない",
	"眠りが浅い",
	"夢をよく見る",
	"不安感が強い",
	"イライラしやすい",
	"気力が出ない",
	"手足が冷える",
	"手足がだるいことがある",
	"手のひらに汗をかく",
	"夜間に足の裏が熱い",
	"ふらつき立ちくらみをする",
	"頭痛持ちである",
	"頭が重く感じる",
	"目が疲れやすい",
	"目が充血する",
	"顔に血色がない",
	"赤ら顔",
	"ニキビ吹き出物が多い",
	"しみ・そばかすが多い",
	"粘る痰や鼻水が出る",
	"水っぽい痰や鼻水が出る",
	"鼻がつまりやすい",
	"耳が聞こえにくい",
	"耳鳴りすることがある",
	"めまいすることがある",
	"唇が割れやすい",
	"唇や歯ぐきが紫色",
	"髪の毛が抜けやすい",
	"髪の毛が細い",
	"髪の毛が少ない",
	"皮膚が乾燥しかさつく",
	"皮膚がしっとりしている",
	"湿疹ができ膿みやすい",
	"かゆみを感じる",
	"食欲がないことが多い",
	"食べるとお腹が張る",
	"下痢しやすい",
	"便が硬い",
	"排便の後すっきりしない",
	"げっぷやおならがよく出る",
	"小便の出が悪い",
	"小便の回数が多い",
	"小便の量が多い",
	"小便の色が濃い",
	"生理がない",
	"月経周期が一定しない",
	"月経血が少ない",
	"月経血に固まりが多い",
	"おりものが多い",
	"勃起不全",
	"早漏気味",
];

const Message = (data) => {
	const copyText = (component) => {
		if (typeof component === "string") {
			return navigator.clipboard.writeText(component);
		}
	};
	const columns = [
		{
			title: "症状",
			dataIndex: "name",
			key: "name",
		},
		{
			title: "程度",
			dataIndex: "level",
			key: "level",
		},
		{
			title: "発症",
			dataIndex: "duration",
			key: "duration",
		},
	];
	useEffect(() => {
		const script = document.createElement("script");
		script.src = process.env.REACT_APP_API_URL + `/script_assets/radar_mini.js`;
		script.async = true;
		document.body.appendChild(script);
	}, []);
	const messageTemplate = (shownData, isOnLeft, showCopy = true) => {
		return (
			<div
				className={
					"chat-message-containter " +
					(isOnLeft ? "" : "chat-message-containter__right")
				}
			>
				<div className="chat-message">{shownData}</div>
				{isOnLeft && showCopy && (
					<div
						className="chat-message__copy"
						onClick={() => copyText(shownData)}
					>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<path
								fillRule="evenodd"
								clipRule="evenodd"
								d="M10.0404 1.66663H5.33333V12H13V4.62619L10.0404 1.66663ZM6.33333 11V2.66663H9.62623L12 5.0404V11H6.33333Z"
								fill="#9E7762"
							/>
							<path
								d="M4 4.49996V13.3333H10.1667V14.3333H3V4.49996H4Z"
								fill="#9E7762"
							/>
						</svg>
					</div>
				)}
			</div>
		);
	};
	// const productCard = (data, isShowRecommendFlag) => {

	//   return (
	//     <div className="product-card">
	//       <div className="product-card__flag">
	//         <img src={Flag} alt="flag" />
	//         <div>おすすめ</div>
	//       </div>
	//       <div className="product-card__title">{data.title}</div>
	//       <div className="product-card__image">
	//         <img src={data.image} alt="product-recommendation" />
	//       </div>
	//       <div className="product-card__description">{data.description}</div>
	//       <div className="product-card__variant">
	//         <div>{data.price}</div>
	//         <div>{data.option1}</div>
	//         <div>{data.option2}</div>
	//       </div>
	//     </div>
	//   );
	// };
	const renderMessage = () => {
		const parsedData = JSON.parse(data.historyData.stepValue);
		switch (data.historyData.stepId) {
			case 1:
				return messageTemplate(
					parsedData.text ? parsedData.text : "",
					true,
					false
				);
			case 2:
				return messageTemplate(
					parsedData.text ? parsedData.text : "",
					true,
					false
				);
			case 3:
				const dataSource = parsedData.historyData.map((data, index) => {
					return { ...data, key: index };
				});
				return messageTemplate(
					<Table
						size={"small"}
						pagination={false}
						dataSource={dataSource}
						columns={columns}
					/>,
					true,
					false
				);
			case 4:
				if (typeof parsedData.physicalQuestionData === "string") {
					const mentalData = parsedData.physicalQuestionData
						.split(",")
						.map(Number);
					const checkedMentalData = [];
					mentalData.forEach(function (item, index) {
						if (item === 1 && mentalTestData[index]) {
							checkedMentalData.push(mentalTestData[index]);
						}
					});
					if (checkedMentalData.length) {
						return messageTemplate(
							<div>
								{checkedMentalData.map((item, index) => {
									return (
										<div
											key={index}
											className="chat-message__physical-question"
										>
											<div>{item}</div>
										</div>
									);
								})}
							</div>,
							true,
							false
						);
					}
				}
				return;
			case 5:
				// Recommendation data
				// if (parsedData && parsedData.type === 'recommendation' && parsedData.products.length) {
				//   return messageTemplate(<div>
				//     {parsedData.products.map((data, index) => {
				//       return (<div key={index} className="chat-message__products">{productCard(data)}</div>)
				//     })}
				//   </div>, true)
				// } else
				if (parsedData && parsedData.type === "graph") {
					const graphSection = parsedData.message
						? messageTemplate(
								<div className="chat-message__mental-result">
									<div>
										<span
											dangerouslySetInnerHTML={{ __html: parsedData.message }}
										></span>
									</div>
									{/* <div className="chat-message__mental-result__canvas">
				      <canvas width="271" height="271" id="sample"></canvas>
				    </div> */}
								</div>,
								true,
								false
						  )
						: null;

					// eslint-disable-next-line no-undef
					// var rc = new html5jp.graph.radar("sample");
					// if (!rc) { return; }
					// var items = [
					//   ["", ...Object.values(parsedData?.result || {})]
					// ];
					// var params = {
					//   aCap: [...Object.keys(parsedData?.result || {})],
					//   aMax: 30,
					//   aMin: 0,
					//   aLinePositions: [0, 7.5, 15, 22.5, 30],
					//   sLabel: false,
					//   aCapColor: "#666666",
					//   legend: false
					// }
					// rc.draw(items, params);
					return graphSection;
				}
				return null;
			case null:
				if (data.historyData.question) {
					return (
						<div>
							{messageTemplate(data.historyData.question, true, false)}
							{data.historyData.answer &&
								messageTemplate(data.historyData.answer, false, false)}
						</div>
					);
				} else {
					if (data.historyData.chatContents.length) {
						const chatData = [];
						data.historyData.chatContents.map((chatContent, index) => {
							const content = chatContent.content;
							if (content && content.productData) {
								const productCard = (
									<div className="message__product-card">
										<div className="message__product-card__title">
											{content.productData.title}
										</div>
										<div className="product-card__image">
											<img
												src={content.productData.image}
												alt="product-recommendation"
											/>
										</div>
										<div className="message__product-card__description">
											{content.productData.description}
										</div>
										<div className="message__product-card__variant">
											<div>
												{Number(content.productData.price).toLocaleString(
													"en-US"
												)}
												円
											</div>
											<div>{content.productData.option1}</div>
											<div>{content.productData.option2}</div>
										</div>
									</div>
								);
								return chatData.push(
									<div key={index}>
										{messageTemplate(
											<div className="chat-message__products">
												{productCard}
											</div>,
											false
										)}
									</div>
								);
							} else if (content && content.message) {
								return chatData.push(
									<div key={index}>
										{messageTemplate(
											content.message,
											chatContent.isDoctor ? false : true,
											chatContent.isDoctor ? false : true
										)}
									</div>
								);
							}
							return null;
						});
						return <div>{chatData}</div>;
					}
				}
				return;
			default:
				return;
		}
	};
	return <div>{renderMessage()}</div>;
};

export default Message;
