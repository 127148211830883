import axios from 'configs/api';

const login = (data) => {
  return axios
    .post("cms/admins/login", data)
    .then((response) => {
      return response.data;
    });
};

const logout = () => {
  return axios.get("cms/admins/logout")
    .then((response) => {
      return response.data;
    })
};

const getAuthUser = () => {
  return axios
    .get("cms/admins/users/currentUser")
    .then((response) => {
      return response.data.data;
    });
};

const AuthService = {
  login,
  logout,
  getAuthUser
};

export default AuthService;
