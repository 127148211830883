import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { handleErrorFromServer } from 'utils/errors';
import { Button, Form, Input, message } from 'antd';
import FAQService from 'apis/FAQService';

import { setTitle } from 'redux/actions/title';

const FAQForm = ({ isEdit, ...rest }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [form] = Form.useForm();
  const { id } = useParams();
  const { TextArea } = Input;

  const onResetForm = () => {
    form.resetFields();
  };

  const createFAQ = (async (values) => {
    try {
      await FAQService.createNewFAQ(values).then(() => {
        message.success("FAQは作成されました。")
        navigate(-1)
      })
    } catch (error) {
      handleErrorFromServer(error)
    }
  })

  const fetchFAQData = (async () => {
    try {
      const FAQData = await FAQService.getDetailFAQ(id)
      form.setFieldsValue(FAQData.data)
    } catch (error) {
      handleErrorFromServer(error)
    }
  })

  const updateFAQ = (async (values) => {
    try {
      await FAQService.updateFAQ(id, values).then(() => {
        message.success("アカウントは変更されました。")
        navigate(-1)
      })
    } catch (error) {
      handleErrorFromServer(error)
    }
  })

  useEffect(() => {
    dispatch(setTitle(isEdit ? 'FAQ編集' : 'FAQ作成'));
    if (isEdit) {
      fetchFAQData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className='layout-container'>
      <div className='faq-page-container'>
        <div className='faq-page-container__form'>
          <Form
            form={form}
            name="createFAQForm"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            colon={false} onFinish={isEdit ? updateFAQ : createFAQ}>
            <Form.Item label="質問" name="question" rules={[{ required: true }]}>
              <TextArea
                className='faq-page-container__form__input'
                autoSize={{ minRows: 1, maxRows: 6 }}
                placeholder="質問内容"
              />
            </Form.Item>
            <Form.Item label="答え" name="answer" rules={[{ required: true }]}>
              <TextArea
                className='faq-page-container__form__input'
                autoSize={{ minRows: 1, maxRows: 10 }}
                placeholder="答え"
              />
            </Form.Item>
            <div className='account-page-container__form--button-container'>
              {!isEdit &&
                <Form.Item style={{ marginRight: "12px" }} >
                  <Button type="secondary" htmlType="button" onClick={onResetForm}>
                    キャンセル
                  </Button>
                </Form.Item>
              }
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {isEdit ? '変更する' : '作成する'}
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </div>
  )
}

export default FAQForm;