import { Layout } from "antd";
import AppSidebar from "layout/AppSidebar";
import AppHeader from "layout/AppHeader";
import { useSelector } from "react-redux";
import { USER_TYPES } from "common/constant";

const { Content } = Layout;

const AppLayout = ({ children }) => {

  const { userType } = useSelector((state) => state.auth.authUser);

  return (
    <Layout>
      <AppSidebar />
      <Layout>
        <AppHeader />
        <Content className={userType === USER_TYPES.ADMIN ? 'layout-content-admin' : 'layout-content-user'}>
          {window.screen.width > 576 && children}
        </Content>
      </Layout>
    </Layout>
  )
}

export default AppLayout;