import React, { useState, useRef, useEffect } from "react";
import { useSelector } from "react-redux";
import io from "socket.io-client";
import { Input, Button, Spin, Image, notification, Tooltip } from "antd";
import ChatService from "apis/ChatService";
import { SearchOutlined } from "@ant-design/icons";
import UserItem from "components/chat/userItem";
import UserAvatar from "components/chat/userAvatar";
import ChatBackground from "assets/images/chat-background.png";
import Message from "components/chat/message";
import Flag from "assets/icons/flag.svg";
import FAQService from "apis/FAQService";
import { Howl } from "howler";
import notificationAudio from "../../assets/nofitication.wav";

const DEFAULT_CHAT_LIST = {
	page: 1,
	perPage: 20,
	sort: "DESC",
};

const DEFAULT_CHAT_DETAIL = {
	page: 1,
	perPage: 20,
	sort: "DESC",
};

const QUICK_ANSWERS = [
	{
		title: "挨１",
		answer:
			"おはようございます。私はアドバイザーの●です。よろしくお願いします。",
	},
	{
		title: "挨２",
		answer: "こんにちは。私はアドバイザーの●です。よろしくお願いします。",
	},
	{
		title: "挨３",
		answer: "こんばんは。私はアドバイザーの●です。よろしくお願いします。",
	},
	{
		title: "伺１",
		answer: "このたびは、どうなさいましたか？",
	},
	{
		title: "伺２",
		answer: "このたびは、どのようなご用件ですか？",
	},
	{
		title: "伺３",
		answer: "何かお困りなことがございますか？",
	},
	{
		title: "共１",
		answer: "そうでしたか。それはおつらいですね。",
	},
	{
		title: "共２",
		answer: "承知しました。それは大変ですね。",
	},
	{
		title: "具体",
		answer: "もう少し具体的に教えていただけますか。",
	},
	{
		title: "役１",
		answer: "私のできる範囲は少ないかもしれませんが、お役に立てれば幸いです。",
	},
	{
		title: "役２",
		answer: "少しでもお役に立てれば幸いです。",
	},
	{
		title: "感１",
		answer: "ありがとうございます。",
	},
	{
		title: "感２",
		answer: "そのようなお言葉をいただきうれしく思います。",
	},
	{
		title: "断１",
		answer:
			"恐れ入りますが、そのようなご要望にはお答えすることはできかねます。",
	},
	{
		title: "断２",
		answer: "大変恐縮ですが、ご回答をすることはできません。",
	},
	{
		title: "謝１",
		answer: "このたびは大変申し訳ございませんでした。",
	},
	{
		title: "謝２",
		answer: "ご不便をおかけして申し訳ありませんでした。",
	},
	{
		title: "後１",
		answer:
			"このたびは有人チャットをご利用いただきありがとうございました。また何かお困りごとがございましたらご相談ください。",
	},
	{
		title: "後２",
		answer:
			"ご利用いただきありがとうございました。また何かお困りごとがございましたらご遠慮なくご利用ください。",
	},
	{
		title: "不便",
		answer:
			"ご不便をおかけして申し訳ございません。アドバイザーが不在の場合は、追ってメールにてご回答しておりますことをご了承ください。",
	},
	{
		title: "待１",
		answer:
			"承知しました。確認いたしますので、このままの状態でしばらくお待ちください。",
	},
	{
		title: "待２",
		answer:
			"恐れ入りますが、薬剤師と相談してまいりますのでこのままの状態でしばらくお待ちください。",
	},
	{
		title: "処１",
		answer:
			"この後、ご入力いただいた症状や体質判定の結果などから、薬局側のAIが処方した漢方薬をお示しいたします。",
	},
	{
		title: "処２",
		answer:
			"この後、ご入力いただいた症状や体質判定の結果などから、私がお選びした漢方薬をお示しいたします。",
	},
	{
		title: "購入",
		answer:
			"よろしければ、お示した漢方薬の下に表示された「カートに追加する」又は「購入する」ボタンをクリックし、ご購入手続きにお進みください。",
	},
	{
		title: "再度",
		answer:
			"恐れ入りますが、KanpoAIの「AI処方」をクリックして、再度AI処方をお試しいただいてよろしいでしょうか。",
	},
	{
		title: "切断",
		answer:
			"なお、カートなどwebサイトを見に行きますと、このチャットは一時的に切断されてしまいますことをご承知おきください。",
	},
	{
		title: "復活",
		answer:
			"ブラウザー（Chromeなど）の戻るボタンを押すと、KanpoAIが復活する場合がございます。",
	},
];

const { TextArea } = Input;

const ChatPage = () => {
	const [onlineStatus, setOnlineStatus] = useState(true);

	const [userList, setUserList] = useState([]);
	const [selectedUser, setSelectedUser] = useState(null);
	const [userChatListQuery, setUserChatListQuery] = useState(DEFAULT_CHAT_LIST);
	const [fetchingUserChatList, setFetchingUserChatList] = useState(0); // 0 for ok, 1 for fetching, 2 for all-fetched

	const [inputValue, setInputValue] = useState("");
	const [faqInputValue, setFaqInputValue] = useState("");

	const [chatHistory, setChatHistory] = useState([]);
	const [chatHistoryQuery, setChatHistoryQuery] = useState(DEFAULT_CHAT_DETAIL);
	const [fetchingChatHistory, setFetchingChatHistory] = useState(0); // 0 for ok, 1 for fetching, 2 for all-fetched
	const [collapsedContent, setCollapsedContent] = useState(false);
	const [chatable, setChatable] = useState(false);
	const [faqData, setFaqData] = useState([]);
	const [recommnendationProducts, setRecommendationProducts] = useState([]);
	const [typingClient, setTypingClient] = useState(null);

	const authUser = useSelector((state) => state.auth.authUser);
	const doctorOnlineStatus = useSelector(
		(state) => state.onlineStatus.onlineStatus
	);

	const socketRef = useRef(null);

	const scrollTopRef = useRef(null);

	const sound = new Howl({
		src: [notificationAudio],
	});

	const openNotification = (clientId) => {
		sound.play();
		notification.success({
			message: `クライアント${clientId}は接続しています。`,
		});
	};

	const updateSelectedUser = (value) => {
		setSelectedUser(value);
	};

	const getUserChatList = async () => {
		const res = await ChatService.getAllUser(DEFAULT_CHAT_LIST);
		setUserChatListQuery(DEFAULT_CHAT_LIST);
		setUserList(res.data);
		setFetchingUserChatList(res.lastPage > res.page ? 0 : 2);
	};

	const checkUserListScroll = (e) => {
		const { offsetHeight, scrollTop, scrollHeight } = e.target;

		if (offsetHeight + scrollTop > scrollHeight - 10) {
			appendUserChatList();
		}
	};

	const appendUserChatList = async () => {
		if (fetchingUserChatList) return;

		setFetchingUserChatList(1);
		const query = {
			...userChatListQuery,
			page: userChatListQuery.page + 1,
		};
		const res = await ChatService.getAllUser(query);

		setUserChatListQuery(query);
		setUserList([...userList, ...res.data]);
		setFetchingUserChatList(res.lastPage > res.page ? 0 : 2);
	};

	const getChatHistory = async (userId) => {
		const res = await ChatService.getHistory(userId, DEFAULT_CHAT_DETAIL);
		setChatHistoryQuery(DEFAULT_CHAT_DETAIL);
		setChatHistory(res.data);
		setFetchingChatHistory(res.lastPage > res.page ? 0 : 2);
	};

	const getProductsRecommendation = async (userId) => {
		const res = await ChatService.getRecommendation(userId);
		if (res.data) {
			const parsedData = JSON.parse(res.data.stepValue);
			if (parsedData && parsedData.products.length) {
				setRecommendationProducts(parsedData.products);
			}
		} else {
			setRecommendationProducts([]);
		}
	};

	const appendChatHistory = async (userId) => {
		if (fetchingChatHistory) return;

		setFetchingChatHistory(1);
		const query = {
			...chatHistoryQuery,
			page: chatHistoryQuery.page + 1,
		};
		const res = await ChatService.getHistory(userId, query);

		setChatHistoryQuery(query);
		setChatHistory([...chatHistory, ...res.data]);
		setFetchingChatHistory(res.lastPage > res.page ? 0 : 2);
	};

	const sendProduct = (product) => {
		if (chatable) {
			socketRef.current.emit("doctorSendMessage", {
				clientId: selectedUser.id,
				userId: authUser.id,
				loginId: authUser.loginId,
				message: JSON.stringify({
					productData: product,
				}),
				isProduct: true,
			});
			const newHistoryData = {
				clientId: selectedUser.id,
				stepId: null,
				stepValue: null,
				question: null,
				answer: null,
				userId: authUser.id,
				chatContents: [
					{
						content: {
							productData: product,
						},
						isDoctor: true,
					},
				],
			};
			setChatHistory([newHistoryData, ...chatHistory.slice(0, -1)]);
			setInputValue("");
			let updatedUserList = [...userList];
			const dataIndex = updatedUserList.findIndex(
				(item) => item.id === selectedUser.id
			);
			if (dataIndex !== -1) {
				updatedUserList[dataIndex].totalUnReader = 0;
				updatedUserList = [
					...updatedUserList.splice(dataIndex, 1),
					...updatedUserList,
				];
			}
			setUserList(updatedUserList);
		}
	};

	const sendMessage = () => {
		if (inputValue && chatable) {
			socketRef.current.emit("doctorSendMessage", {
				clientId: selectedUser.id,
				userId: authUser.id,
				loginId: authUser.loginId,
				message: JSON.stringify({ message: inputValue }),
			});
			const newHistoryData = {
				clientId: selectedUser.id,
				stepId: null,
				stepValue: null,
				question: null,
				answer: null,
				userId: authUser.id,
				chatContents: [
					{
						content: {
							message: document.getElementById("chatInput").innerHTML,
						},
						isDoctor: true,
					},
				],
			};
			setChatHistory([newHistoryData, ...chatHistory.slice(0, -1)]);
			setInputValue("");
			let updatedUserList = [...userList];
			const dataIndex = updatedUserList.findIndex(
				(item) => item.id === selectedUser.id
			);
			if (dataIndex !== -1) {
				updatedUserList[dataIndex].totalUnReader = 0;
				updatedUserList = [
					...updatedUserList.splice(dataIndex, 1),
					...updatedUserList,
				];
			}
			setUserList(updatedUserList);
		}
	};

	const queryData = async (filterData) => {
		const { data } = await FAQService.getAllFAQ(filterData);
		setFaqData(() => data.data.map((item) => ({ ...item, key: item.id })));
	};

	const copyText = (component) => {
		if (typeof component === "string") {
			return navigator.clipboard.writeText(component);
		}
	};

	const copyTextToChat = (component) => {
		if (typeof component === "string") {
			setInputValue(component);
		}
	};

	useEffect(() => {
		getUserChatList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setOnlineStatus(doctorOnlineStatus);
	}, [doctorOnlineStatus]);

	// Socket IO initialization
	useEffect(() => {
		if (onlineStatus) {
			socketRef.current = io(process.env.REACT_APP_SOCKET_URL);
			socketRef.current.connect();
			socketRef.current.emit("doctorConnect", {
				userId: authUser.id,
				loginId: authUser.loginId,
			});
		} else {
			socketRef.current.disconnect();
		}

		socketRef.current.on("clientIsKept", function (data) {
			if (userList.length) {
				const keepingDoctorId = data.userId;
				const keptClientId = data.clientId;
				let updatedUserList = [...userList];
				const dataIndex = updatedUserList.findIndex(
					(item) => item.id === keptClientId
				);
				if (dataIndex !== -1) {
					updatedUserList[dataIndex].keepDoctor = keepingDoctorId;
				}
				setUserList(updatedUserList);
			}
		});

		socketRef.current.on("doctorDisconnected", function (data) {
			if (userList.length) {
				const disconnectedDoctorId = data.userId;
				let updatedUserList = userList.map((user) => {
					if (user.keepDoctor && user.keepDoctor === disconnectedDoctorId) {
						return { ...user, keepDoctor: null };
					} else return user;
				});
				setUserList(updatedUserList);
			}
		});

		socketRef.current.on("clientConnected", async function (data) {
			openNotification(data.clientId);
			let updatedUserList = [...userList];
			const dataIndex = updatedUserList.findIndex(
				(item) => item.id === data.clientId
			);
			if (dataIndex !== -1) {
				updatedUserList[dataIndex].realtimeStatus = true;
				updatedUserList[dataIndex].email = data.email;
				updatedUserList[dataIndex].kanpoId = data.kanpoId;
				updatedUserList = [
					...updatedUserList.splice(dataIndex, 1),
					...updatedUserList,
				];
			} else {
				updatedUserList = [data.data, ...updatedUserList.slice(0, -1)];
			}
			setUserList(updatedUserList);
			if (selectedUser) {
				if (data.clientId === selectedUser.id) {
					setSelectedUser({ ...selectedUser, realtimeStatus: true });
				}
				setChatable(
					(onlineStatus &&
						selectedUser.realtimeStatus &&
						!selectedUser.keepDoctor) ||
						(onlineStatus &&
							selectedUser.realtimeStatus &&
							selectedUser.keepDoctor === authUser.id)
				);
			}
		});
		socketRef.current.on("clientDisconnected", function (data) {
			const updatedUserList = [...userList];
			const dataIndex = updatedUserList.findIndex(
				(item) => item.id === data.clientId
			);
			if (dataIndex !== -1) {
				updatedUserList[dataIndex].realtimeStatus = false;
			}
			setUserList(updatedUserList);
			if (selectedUser && data.clientId === selectedUser.id) {
				setSelectedUser({ ...selectedUser, realtimeStatus: false });
			}
			setChatable(false);
		});
		socketRef.current.on("updateNewMessageNumber", function (data) {
			let updatedUserList = [...userList];

			const dataIndex = updatedUserList.findIndex(
				(item) => item.id === data.clientId
			);
			if (dataIndex !== -1) {
				updatedUserList[dataIndex].totalUnReader = data.totalUnReader;
				updatedUserList = [
					...updatedUserList.splice(dataIndex, 1),
					...updatedUserList,
				];
			}

			setUserList(updatedUserList);
		});
		socketRef.current.on("clientIsTypingMessage", function (data) {
			if (selectedUser && data.clientId === selectedUser.id) {
				setTypingClient(data.clientId);
			}
		});
		socketRef.current.on("clientTypedMessageDone", function (data) {
			if (selectedUser && data.clientId === selectedUser.id) {
				setTypingClient(null);
			}
		});
		socketRef.current.on("newClientMessageArrives", function (data) {
			// TODO: update lastest message on userList
			if (data.clientId === selectedUser.id) {
				const newHistoryData = {
					clientId: data.clientId,
					stepId: null,
					stepValue: null,
					question: null,
					answer: null,
					userId: authUser.id,
					chatContents: [
						{
							content: JSON.parse(data.message) || "",
							isDoctor: false,
						},
					],
				};
				setChatHistory([newHistoryData, ...chatHistory.slice(0, -1)]);
			}
		});

		return () => {
			socketRef.current.off("clientConnected");
			socketRef.current.off("clientDisconnected");
			socketRef.current.off("updateNewMessageNumber");
			socketRef.current.off("newClientMessageArrives");
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [onlineStatus, userList, selectedUser, chatHistory]);

	useEffect(() => {
		if (selectedUser) {
			setChatable(
				(onlineStatus &&
					selectedUser.realtimeStatus &&
					!selectedUser.keepDoctor) ||
					(onlineStatus &&
						selectedUser.realtimeStatus &&
						selectedUser.keepDoctor === authUser.id)
			);
			getChatHistory(selectedUser.id);
		}
	}, [selectedUser, onlineStatus, authUser]);

	useEffect(() => {
		if (selectedUser) {
			getProductsRecommendation(selectedUser.id);
		}
	}, [selectedUser]);

	const checkHistoryScroll = (e) => {
		const { offsetHeight, scrollTop, scrollHeight } = e.target;

		if (offsetHeight - scrollTop > scrollHeight - 10) {
			if (selectedUser) {
				appendChatHistory(selectedUser.id);
			}
		}
	};

	// useEffect(() => {
	//   scrollToBottom()
	// }, [chatHistory])

	return (
		<div className="layout-container">
			<div className="chat-container">
				<div className="chat-container__list" onScroll={checkUserListScroll}>
					<div className="chat-container__list__header">
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
								marginBottom: "12px",
							}}
						>
							<div className="chat-container__list__header__title">
								メッセージ
							</div>
							<div
								style={{
									padding: "4px",
									backgroundColor: "#9E7762",
									color: "white",
									borderRadius: "4px",
									cursor: "pointer",
								}}
								onClick={() => {
									getUserChatList();
									setSelectedUser(null);
								}}
							>
								スタート
							</div>
						</div>
						<div className="chat-container__list__header__search">
							<Input placeholder="検索" prefix={<SearchOutlined />} />
							<div className="chat-container__list__header__search__icon">
								<svg
									width="24"
									height="24"
									viewBox="0 0 24 24"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M2 3H22L14 12.46V21L10 19V12.46L2 3Z"
										stroke="#9E7762"
										strokeLinecap="round"
										strokeLinejoin="round"
									/>
									<circle
										cx="19"
										cy="19"
										r="4.5"
										fill="#6 1BF87"
										stroke="white"
									/>
								</svg>
							</div>
						</div>
					</div>
					<div className="chat-container__list__body">
						{userList.map((user) => (
							<UserItem
								key={user.id}
								id={user.id}
								kanpoId={user.kanpoId}
								name={user.email}
								realtimeStatus={user.realtimeStatus}
								unreadNumber={user.totalUnReader}
								messageTime={user.messageTime ? user.messageTime : null}
								latestMessage={
									user.histories?.length ? user.histories[0].question : null
								}
								selectedUser={selectedUser}
								keepDoctor={user.keepDoctor}
								setSelectedUser={updateSelectedUser}
							/>
						))}
						{fetchingUserChatList === 1 && (
							<Spin style={{ height: "initial", marginTop: 12 }} />
						)}
					</div>
				</div>

				<div
					className={
						"chat-container__main " + (collapsedContent ? "narrow" : "full")
					}
				>
					{selectedUser ? (
						<div style={{ position: "relative" }}>
							<div>
								<div className="chat-container__main__header">
									<div className="chat-container__main__header__left">
										<UserAvatar
											realtimeStatus={selectedUser.realtimeStatus}
											mainChat={true}
										/>
										<div className="chat-container__main__header__left__name-section">
											<div>
												{selectedUser.kanpoId
													? selectedUser.kanpoId
													: selectedUser.id}
											</div>
											<div>{selectedUser.name}</div>
										</div>
									</div>
									<div>
										<span>
											<svg
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M11 2.25C6.16751 2.25 2.25 6.16751 2.25 11C2.25 15.8325 6.16751 19.75 11 19.75C13.1462 19.75 15.112 18.9773 16.6342 17.6949L21 22.0607L22.0607 21L17.6949 16.6342C18.9773 15.112 19.75 13.1462 19.75 11C19.75 6.16751 15.8325 2.25 11 2.25ZM3.75 11C3.75 6.99594 6.99594 3.75 11 3.75C15.0041 3.75 18.25 6.99594 18.25 11C18.25 15.0041 15.0041 18.25 11 18.25C6.99594 18.25 3.75 15.0041 3.75 11Z"
													fill="#696969"
												/>
											</svg>
										</span>
										{chatable && (
											<span
												onClick={() => setCollapsedContent(!collapsedContent)}
											>
												<svg
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M4 14H10V20"
														stroke="#696969"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M20 10H14V4"
														stroke="#696969"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M14 10L21 3"
														stroke="#696969"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
													<path
														d="M3 21L10 14"
														stroke="#696969"
														strokeWidth="1.5"
														strokeLinecap="round"
														strokeLinejoin="round"
													/>
												</svg>
											</span>
										)}
									</div>
								</div>
								<div
									className="chat-container__main__body"
									onScroll={checkHistoryScroll}
									ref={scrollTopRef}
								>
									{chatHistory.length
										? chatHistory.map((history, index) => (
												<Message key={index} historyData={history} />
										  ))
										: null}
									{fetchingChatHistory === 1 && (
										<Spin style={{ height: "initial", marginBottom: 12 }} />
									)}
								</div>
								{chatable && typingClient && (
									<div className="chat-container__main__typing">
										入力中。。。
									</div>
								)}
							</div>
							{chatable && (
								<div className="chat-container__main__input">
									{/* <Input
										id="chatInput"
										placeholder="内容入力してください"
										value={inputValue}
										onChange={(e) => setInputValue(e.target.value)}
										onKeyPress={(e) => {
											socketRef.current.emit("doctorIsTyping", {
												clientId: selectedUser.id,
											});
											if (e.key === "Enter") {
												sendMessage();
											}
										}}
										suffix={
											<svg
												onClick={() => sendMessage()}
												width="24"
												height="24"
												viewBox="0 0 24 24"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													d="M19.02 1.13003C19.01 1.13003 18.99 1.14003 18.98 1.14003L3.04001 6.60003C0.270008 7.49003 0.350008 11.4 3.16001 12.24C3.17001 12.24 3.18001 12.25 3.19001 12.25L8.54001 13.69C8.55001 13.69 8.56001 13.7 8.57001 13.7C9.39001 13.89 10.01 14.52 10.32 15.49L11.75 20.83C12.5 23.67 16.4 23.68 17.38 21.01V21L22.82 5.00003C23.7 2.51003 21.32 0.450028 19.02 1.13003ZM15.96 20.51C15.71 21.17 15.12 21.51 14.5 21.5C13.9 21.49 13.38 21.16 13.19 20.46L11.76 15.11C11.75 15.09 11.75 15.07 11.74 15.05C11.31 13.68 10.34 12.58 8.91001 12.24L3.58001 10.81C2.11001 10.36 2.20001 8.45003 3.49001 8.03003L3.52001 8.02003L19.45 2.57003C20.06 2.39003 20.67 2.58003 21.07 2.96003C21.45 3.32003 21.64 3.85003 21.41 4.51003L15.96 20.51Z"
													fill="#9E7762"
												/>
											</svg>
										}
									/> */}
									<div className="chat-container__main__input__container">
										<TextArea
											id="chatInput"
											placeholder="内容入力してください"
											value={inputValue}
											onChange={(e) => setInputValue(e.target.value)}
											onKeyPress={(e) => {
												socketRef.current.emit("doctorIsTyping", {
													clientId: selectedUser.id,
												});
												if (e.key === "Enter") {
													if (e.shiftKey) {
														e.preventDefault();
														setInputValue(e.target.value + "\r\n");
													} else {
														e.preventDefault();
														sendMessage();
													}
												}
											}}
											suffix={
												<svg
													onClick={() => sendMessage()}
													width="24"
													height="24"
													viewBox="0 0 24 24"
													fill="none"
													xmlns="http://www.w3.org/2000/svg"
												>
													<path
														d="M19.02 1.13003C19.01 1.13003 18.99 1.14003 18.98 1.14003L3.04001 6.60003C0.270008 7.49003 0.350008 11.4 3.16001 12.24C3.17001 12.24 3.18001 12.25 3.19001 12.25L8.54001 13.69C8.55001 13.69 8.56001 13.7 8.57001 13.7C9.39001 13.89 10.01 14.52 10.32 15.49L11.75 20.83C12.5 23.67 16.4 23.68 17.38 21.01V21L22.82 5.00003C23.7 2.51003 21.32 0.450028 19.02 1.13003ZM15.96 20.51C15.71 21.17 15.12 21.51 14.5 21.5C13.9 21.49 13.38 21.16 13.19 20.46L11.76 15.11C11.75 15.09 11.75 15.07 11.74 15.05C11.31 13.68 10.34 12.58 8.91001 12.24L3.58001 10.81C2.11001 10.36 2.20001 8.45003 3.49001 8.03003L3.52001 8.02003L19.45 2.57003C20.06 2.39003 20.67 2.58003 21.07 2.96003C21.45 3.32003 21.64 3.85003 21.41 4.51003L15.96 20.51Z"
														fill="#9E7762"
													/>
												</svg>
											}
											style={{
												resize: "none",
											}}
										/>
										<svg
											onClick={() => sendMessage()}
											width="24"
											height="24"
											viewBox="0 0 24 24"
											fill="none"
											xmlns="http://www.w3.org/2000/svg"
										>
											<path
												d="M19.02 1.13003C19.01 1.13003 18.99 1.14003 18.98 1.14003L3.04001 6.60003C0.270008 7.49003 0.350008 11.4 3.16001 12.24C3.17001 12.24 3.18001 12.25 3.19001 12.25L8.54001 13.69C8.55001 13.69 8.56001 13.7 8.57001 13.7C9.39001 13.89 10.01 14.52 10.32 15.49L11.75 20.83C12.5 23.67 16.4 23.68 17.38 21.01V21L22.82 5.00003C23.7 2.51003 21.32 0.450028 19.02 1.13003ZM15.96 20.51C15.71 21.17 15.12 21.51 14.5 21.5C13.9 21.49 13.38 21.16 13.19 20.46L11.76 15.11C11.75 15.09 11.75 15.07 11.74 15.05C11.31 13.68 10.34 12.58 8.91001 12.24L3.58001 10.81C2.11001 10.36 2.20001 8.45003 3.49001 8.03003L3.52001 8.02003L19.45 2.57003C20.06 2.39003 20.67 2.58003 21.07 2.96003C21.45 3.32003 21.64 3.85003 21.41 4.51003L15.96 20.51Z"
												fill="#9E7762"
											/>
										</svg>
									</div>
								</div>
							)}
						</div>
					) : (
						<div className="chat-container__main__background">
							<Image
								src={ChatBackground}
								width={400}
								height={"auto"}
								preview={false}
							/>
						</div>
					)}
				</div>
				{selectedUser && (
					<div
						className={
							"recommend-container " +
							(collapsedContent ? "show-recommend" : "")
						}
					>
						<div className="recommend-container__search">
							<div
								style={{ height: "calc(100% - 160px)", paddingBottom: "12px" }}
							>
								<div className="recommend-container__search__main">
									<Input
										id="recommendInput"
										placeholder="検索"
										value={faqInputValue}
										onChange={(e) => setFaqInputValue(e.target.value)}
										onKeyPress={(e) => {
											if (e.key === "Enter")
												queryData({ search: faqInputValue });
										}}
										prefix={
											<svg
												width="16"
												height="16"
												viewBox="0 0 16 16"
												fill="none"
												xmlns="http://www.w3.org/2000/svg"
											>
												<path
													fillRule="evenodd"
													clipRule="evenodd"
													d="M7.33333 1.5C4.11167 1.5 1.5 4.11167 1.5 7.33333C1.5 10.555 4.11167 13.1667 7.33333 13.1667C8.76415 13.1667 10.0747 12.6515 11.0895 11.7966L14 14.7071L14.7071 14L11.7966 11.0895C12.6515 10.0747 13.1667 8.76415 13.1667 7.33333C13.1667 4.11167 10.555 1.5 7.33333 1.5ZM2.5 7.33333C2.5 4.66396 4.66396 2.5 7.33333 2.5C10.0027 2.5 12.1667 4.66396 12.1667 7.33333C12.1667 10.0027 10.0027 12.1667 7.33333 12.1667C4.66396 12.1667 2.5 10.0027 2.5 7.33333Z"
													fill="#696969"
												/>
											</svg>
										}
									/>
									<Button
										onClick={() => queryData({ search: faqInputValue })}
										type="primary"
									>
										検索
									</Button>
								</div>
								<div style={{ height: "calc(100% - 44px)" }}>
									{faqData.length ? (
										<div className="recommend-container__search__result">
											{faqData.map((data, index) => (
												<div
													key={index}
													className="recommend-container__search__result__item"
												>
													<div className="recommend-container__search__result__item__text">
														<div>Q:{data.question || ""}</div>
														<div>A:{data.answer || ""}</div>
													</div>
													<div>
														<div className="mb-1">
															<Button
																onClick={() => copyText(data.question)}
																type="secondary"
															>
																Qコピー
															</Button>
														</div>
														<div>
															<Button
																onClick={() => copyText(data.answer)}
																type="secondary"
															>
																Aコピー
															</Button>
														</div>
													</div>
												</div>
											))}
										</div>
									) : (
										""
									)}
								</div>
							</div>
							<div className="recommend-container__search__quick-answer__container">
								{QUICK_ANSWERS.map((answer, index) => (
									<Tooltip
										key={index}
										placement="left"
										title={answer.answer}
										color={"#F3F3F3"}
										overlayInnerStyle={{ color: "#121212" }}
										arrowPointAtCenter
									>
										<div
											className="recommend-container__search__quick-answer"
											onClick={() => copyTextToChat(answer.answer)}
										>
											<div className="recommend-container__search__quick-answer__title">
												{answer.title}
											</div>
										</div>
									</Tooltip>
								))}
							</div>
						</div>
						<div>
							<div className="recommend-container__button-group">
								<Button
									type="primary"
									onClick={() =>
										copyText("https://kanponow.com/account?view=taisitu_again")
									}
								>
									再び体質判定
								</Button>
								<Button
									type="primary"
									onClick={() => {
										window.open(
											"https://kanponow.net/kanri/kanpo-search",
											"_blank"
										);
									}}
								>
									漢方薬検索
								</Button>
							</div>
							<div className="recommend-container__product-container">
								{recommnendationProducts.length
									? recommnendationProducts.map((product, index) => (
											<div key={index} className="product-card">
												<div>
													{index === 0 ? (
														<div className="product-card__flag">
															<img src={Flag} alt="flag" />
															<div>おすすめ</div>
														</div>
													) : null}
													<div className="product-card__title">
														{product.title}
													</div>
												</div>
												<div className="product-card__image">
													<img
														src={product.image}
														alt="product-recommendation"
													/>
													<Button
														className="product-card__button"
														onClick={() => sendProduct(product)}
														type="secondary"
													>
														送信
													</Button>
												</div>
											</div>
									  ))
									: null}
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default ChatPage;
