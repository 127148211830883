import { useNavigate } from 'react-router-dom';
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import React, { useState, useEffect } from 'react';
import { login } from 'redux/actions/auth';
import { useDispatch, useSelector } from "react-redux";
import { handleErrorFromServer } from 'utils/errors';
import { USER_TYPES } from 'common/constant';
import { setOnlineStatus } from 'redux/actions/onlineStatus';

const Signin = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.auth.authUser);

  useEffect(() => {
    if (authUser) {
      if (authUser.role === USER_TYPES.ADMIN) {
        navigate('/faq');
      } else if (authUser.role === USER_TYPES.DOCTOR) {
        navigate('/chat');
      }
    }
  }, [authUser, navigate]);

  const onFinish = (values) => {
    setLoading(true);
    dispatch(login(values))
      .then(res => {
        if (res && res.statusCode === 200) {
          localStorage.setItem("accessToken", res.authToken);
          localStorage.setItem("refreshToken", res.refreshToken);
          dispatch(setOnlineStatus(true));
          if (res.data.role === USER_TYPES.ADMIN) {
            navigate('/faq');
          } else if (res.data.role === USER_TYPES.DOCTOR) {
            navigate('/chat');
          }
        }
      })
      .catch(err => {
        handleErrorFromServer(err)
      })
      .finally(() => {
        setLoading(false);
      })
  };

  return (
    <div className="signin-form--wrapper">
      <Form
        name="normal_login"
        className="login-form"
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
      >
        <div className="title signin-form--title">KanpoAI</div>
        <Form.Item
          name="loginId"
          className="signin-form--input"
          rules={[
            {
              required: true,
              message: 'ログインIDを入力してください!',
            },
          ]}
        >
          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="ログインID" />
        </Form.Item>
        <Form.Item
          name="password"
          className="signin-form--input"
          rules={[
            {
              required: true,
              message: 'パスワードを入力してください!',
            },
          ]}
        >
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="パスワード"
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
            ログインする
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Signin;
