import axios from 'configs/api';

const getListFAQ = (data) => {
  return axios
    .get("cms/faqs", { params: data })
    .then((response) => {
      return response.data;
    });
};

const getAllFAQ = (data) => {
  return axios
    .get("cms/faqs/all", { params: data })
    .then((response) => {
      return response.data;
    });
};

const createNewFAQ = (data) => {
  return axios.post("cms/faqs", data)
  .then((response) => response.data)
}

const getDetailFAQ = (id) => {
  return axios.get(`cms/faqs/${id}`)
  .then((response) => {
    return response.data;
  });
}

const updateFAQ = (id, data) => {
  return axios.patch(`cms/faqs/${id}`, data)
  .then((response) => response.data)
}

const deleteFAQ = (id) => {
  return axios.delete(`cms/faqs/${id}`)
  .then((response) => {
    return response.data;
  });
}

const FAQService = {
  getListFAQ,
  getAllFAQ,
  createNewFAQ,
  getDetailFAQ,
  updateFAQ,
  deleteFAQ
};

export default FAQService;
