import AppLayout from "layout";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Navigate,
  Route,
  Routes,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";

// requests
import { getAuthUser } from "redux/actions/auth";
import { USER_TYPES } from "common/constant";

import Signin from "routes/signin";
// import Error404 from "routes/error/404";
import Loading from "components/loading";
import FAQPage from "./faq";
import CreateFAQPage from "./faq/create";
import EditFAQPage from "./faq/edit";
import SymptomPage from "./symptom";
import AccountPage from "./account";
import CreateAccountPage from "./account/create";
import EditAccountPage from "./account/edit"
import ChatPage from "./chat";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const authUser = useSelector((state) => state.auth.authUser);

  localStorage.setItem("userType", authUser.role);
  if (authUser) {
    return children;
  }

  return <Navigate to="/signin" state={{ from: location }} replace />;
};

const AppRoutes = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const authUser = useSelector((state) => state.auth.authUser);
  useEffect(() => {
    const getData = async () => {
      try {
        await dispatch(getAuthUser());
      } catch (err) {
        navigate("/signin");
      } finally {
        setLoading(false);
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;

  return (
    <Routes>
      <Route path="*" element={<Navigate to="/signin" replace />} />
      <Route exact path="/signin" element={<Signin />} />
      <Route
        element={
          <ProtectedRoute>
            <AppLayout>
              <Outlet />
            </AppLayout>
          </ProtectedRoute>
        }
      >
        {authUser && (
          <>
            {authUser.role === USER_TYPES.ADMIN ? (
              <Route>
                <Route exact path="/" element={<Navigate to="/faq" replace />} />
                <Route exact path="/faq" element={<FAQPage />} />
                <Route exact path="/faq/create" element={<CreateFAQPage />} />
                <Route exact path="/faq/edit/:id" element={<EditFAQPage />} />
                <Route exact path="/symptom" element={<SymptomPage />} />
                <Route exact path="/account" element={<AccountPage />} />
                <Route exact path="/account/create" element={<CreateAccountPage />} />
                <Route exact path="/account/edit/:id" element={<EditAccountPage />} />
              </Route>
            ) : (
              <Route>
                <Route exact path="/" element={<Navigate to="/chat" replace />} />
                <Route exact path="/chat" element={<ChatPage />} />
              </Route>
            )}
          </>
        )}
      </Route>
    </Routes>
  );
};

export default AppRoutes;
