import message from 'configs/message';

export const handleErrorFromServer = (error) => {
  if (error?.response?.data) {
    if (error?.response?.data?.statusCode === 400 && error?.response?.data?.errors?.length > 0) {
      error?.response?.data?.errors?.forEach((error) => message.error(error?.msg))
    } else {
      message.error(error?.response?.data.message)
    }
  } else {
    message.error(error?.message)
  }
}
