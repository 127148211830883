import React from "react"
import AllCheckedIcon from "assets/icons/all-check.svg"
import UserAvatar from "./userAvatar"

const UserItem = (data) => {
  const userId = data?.id ? data.id : ''
  const userName = data?.name ? data.name : ''
  const realtimeStatus = data?.realtimeStatus ? data.realtimeStatus : false
  const numberOfUnreadMessages = data?.unreadNumber ? data.unreadNumber : 0
  const messageTime = data?.messageTime ? data.messageTime : ''
  const latestMessage = data?.lastestMessage ? data.lastestMessage : ''

  return (
    <div
      className={"user-item-container " + (data.selectedUser?.id === data.id ? 'user-item-container__selected' : '')}
      onClick={() => data.setSelectedUser(data)}
    >
      <div className="user-item-container__left">
        <div className="user-item-container__avatar">
          <UserAvatar realtimeStatus={realtimeStatus} isClicked={data.selectedUser?.id === data.id ? true : false} />
        </div>
        <div className="user-item-container__main">
          <div>{userId}</div>
          <div>{userName}</div>
          <div>{latestMessage}</div>
        </div>
      </div>
      <div className="user-item-container__time">
        <div>{messageTime}</div>
        <div>
          {numberOfUnreadMessages ?
            <p> {numberOfUnreadMessages > 9 ? '9+' : numberOfUnreadMessages} </p>
            : <img src={AllCheckedIcon} alt="all-check"
            />}
        </div>
      </div>
    </div>
  )
}

export default UserItem;