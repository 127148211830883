import { combineReducers } from 'redux';
import authReducer from 'redux/reducers/AuthReducer';
import titleReducer from 'redux/reducers/TitleReducer';
import onlineStatusReducer from './OnlineStatusReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  title: titleReducer,
  onlineStatus: onlineStatusReducer,
});

export default rootReducer;