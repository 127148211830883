import axios from 'configs/api';

const createSymptom = async (body) => {
  try {
    const { data } = await axios.post("/cms/symptoms", body)
    return data.data;
  } catch (err) {
    throw err
  }
};

const getSymptoms = async (value) => {
  try {
    const { data } = await axios.get("/cms/symptoms", { params: value })
    return data.data;
  } catch (err) {
    throw err
  }
};

const updateSymptom = async (id, body) => {
  try {
    const { data } = await axios.patch(`/cms/symptoms/${id}`, body)
    return data.data;
  } catch (err) {
    throw err
  }
};

const deleteSymptom = async (id) => {
  try {
      const { data } = await axios.delete(`/cms/symptoms/${id}`)
      return data;
  }
  catch (err) {
      throw err
  }
}

const SymptomService = {
    createSymptom,
    getSymptoms,
    updateSymptom,
    deleteSymptom,
};

export default SymptomService;
