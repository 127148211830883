import { Provider } from 'react-redux';
import store from 'redux/store';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import AppRoutes from 'routes';
import 'antd/dist/antd.less';
import 'assets/styles/index.scss';

import jaJP from 'antd/es/locale/ja_JP';
import setupInterceptors from "apis/setupInterceptors";

const App = () => {
  return (
    <Provider store={store}>
      <ConfigProvider locale={jaJP}>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  )
}

setupInterceptors(store);

export default App;
