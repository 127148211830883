import axios from "configs/api";

const createAccount = async (body) => {
  try {
    const formData = new FormData();
    if (body.file) {
      formData.append("file", body.file);
    }
    formData.append("email", body.email);
    formData.append("fullName", body.fullName);
    formData.append("password", body.password);
    formData.append("phoneNumber", body.phoneNumber);
    formData.append("role", body.role);
    formData.append("birthday", body.birthday);
    formData.append("loginId", body.loginId);

    const config = {
      headers: {
        "Content-type":
          "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
      },
    };
    const { data } = await axios.post("/cms/users", formData, config);

    return data.data;
  } catch (err) {
    throw err;
  }
};

const getAccounts = async (value) => {
  try {
    const { data } = await axios.get("/cms/users", { params: value });
    return data.data;
  } catch (err) {
    throw err;
  }
};

const getAccountDetail = async (id) => {
  try {
    const { data } = await axios.get(`cms/users/${id}`);
    return data.data;
  } catch (err) {
    throw err;
  }
};

const updateAccount = async (id, body) => {
  try {
    const formData = new FormData();
    if (body.file) {
      formData.append("file", body.file);
    } else {
      formData.append("avatar", null);
    }
    formData.append("email", body.email);
    formData.append("fullName", body.fullName);
    if (body.password) {
      formData.append("password", body.password);
    }
    formData.append("phoneNumber", body.phoneNumber);
    formData.append("role", body.role);
    formData.append("birthday", body.birthday);

    const config = {
      headers: {
        "Content-type":
          "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
      },
    };
    const { data } = await axios.patch(`/cms/users/${id}`, formData, config);

    return data.data;
  } catch (err) {
    throw err;
  }
};

const updateNotification = async (body) => {
  try {
    const formData = new FormData();
    if (body.hasOwnProperty("isNotification")) {
      formData.append("isNotification", body.isNotification);
    }

    const config = {
      headers: {
        "Content-type":
          "multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s",
      },
    };
    const { data } = await axios.patch(
      "cms/users/updateNotification",
      formData,
      config
    );
    return data.data;
  } catch (err) {
    throw err;
  }
};

const deleteAccount = async (id) => {
  try {
    const { data } = await axios.delete(`/cms/users/${id}`);
    return data;
  } catch (err) {
    throw err;
  }
};

const AccountService = {
  createAccount,
  getAccounts,
  updateAccount,
  deleteAccount,
  getAccountDetail,
  updateNotification,
};

export default AccountService;
