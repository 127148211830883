import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux';
import { setTitle } from 'redux/actions/title';
import { Table } from 'antd';
import FAQService from 'apis/FAQService';
import message from 'configs/message';
import { PAGE_SIZE_OPTIONS } from 'common/constant';
import { formatLongText, handleSuccessFromServer } from 'utils/helpers';
import FormHeader from 'components/formHeader';
import ModalDelete from 'components/modal/modalDelete';
import Loading from 'components/loading';
import EditIcon from 'assets/icons/edit.svg';
import DeleteIcon from 'assets/icons/delete.svg';

const INITIAL_SEARCH = {
  search: '',
  page: 1,
  perPage: 10
}

const FAQPage = () => {
  const columns = [
    {
      title: '質問',
      dataIndex: 'time',
      render: (_, record) => formatLongText(record?.question),
    },
    {
      title: '回答',
      dataIndex: 'answer',
      render: (_, record) => formatLongText(record?.answer)
    },
    {
      title: '操作',
      dataIndex: 'manipulation',
      render: (_, record) => {
        return (
          <div className="table-action">
            <img src={EditIcon} alt="edit" onClick={() => handleEditFAQ(record)} />
            <img src={DeleteIcon} alt="delete" onClick={() => handleDeleteFAQ(record)} />
          </div>
        )
      }
    },
  ];
  const [listFAQs, setListFAQS] = useState([])
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const queryData = async (filterData) => {
    const { data } = await FAQService.getListFAQ(filterData)
    const _lastPage = data.lastPage ? data.lastPage : 1;
    if (filterData.page > _lastPage) {
      setFilterData((filterData) => ({ ...filterData, page: _lastPage }))
      queryData({ ...filterData, page: _lastPage })
    } else {
      setListFAQS(() => data.data.map((item) => ({ ...item, key: item.id })))
      setTotalFAQs(() => data.total)
    }
  }
  const [isFetchingData, setIsFetchingData] = useState(false)
  useEffect(() => {
    const getDataFirstLoad = async () => {
      setIsFetchingData(() => true)
      await queryData(INITIAL_SEARCH)
      setIsFetchingData(() => false)
    }
    getDataFirstLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => dispatch(setTitle('FAQ管理')));

  const [selectedFAQs, setSelectedFAQs] = useState([]);
  const [filterData, setFilterData] = useState(INITIAL_SEARCH)
  const [isShowModalDelete, setIsShowModalDelete] = useState(false)
  const [isShowModalDeleteList, setIsShowModalDeleteList] = useState(false)
  const [isSendingData, setIsSendingData] = useState(false)
  const [deletingFAQID, setDeletingFAQID] = useState(null)
  const [totalFAQs, setTotalFAQs] = useState(0)
  const handleEditFAQ = (record) => {
    return navigate(`/faq/edit/${record.id}`)
  }
  const handleDeleteFAQ = (data) => {
    setDeletingFAQID(data?.id)
    setIsShowModalDelete(() => true)
  }
  const onSelectChange = (selectedRowKeys, selectedRows, info) => {
    setSelectedFAQs(selectedRows.map((item) => item.id));
  }
  const rowSelection = {
    selectedFAQs,
    onChange: onSelectChange,
  }
  const handleSearchFAQ = async () => {
    setFilterData((filterData) => ({ ...filterData, page: 1 }))
    await queryData({ ...filterData, page: 1 })
  }
  const handleDeleteListItem = async () => {
    setIsShowModalDeleteList(() => true)
  }
  const handleTableChange = ({ current, pageSize }) => {
    setFilterData((filterData) => ({ ...filterData, page: current, perPage: pageSize }))
    queryData({ ...filterData, page: current, perPage: pageSize })
  }
  const handleChangeSearch = (e) => setFilterData((filterData) => ({ ...filterData, search: e.target.value }))
  const handleClickConfirmDeleteList = async () => {
    setIsSendingData(true)
    try {
      const res = await Promise.all(selectedFAQs.map((item) => FAQService.deleteFAQ(item)))
      handleSuccessFromServer(res[0])
      setIsShowModalDeleteList(() => false)
      setSelectedFAQs([])
      await queryData(filterData)
    } catch (error) {
      message.error(error?.message)
    }
    setIsSendingData(false)
  }
  const handleConfirmDeleteFAQ = async () => {
    setIsSendingData(true)
    try {
      const res = await FAQService.deleteFAQ(deletingFAQID)
      handleSuccessFromServer(res)
      await queryData(filterData)
      setIsShowModalDelete(() => false)
    } catch (error) {
      message.error(error?.message)
    }
    setIsSendingData(false)
  }
  return (
    <div className='layout-container'>
      <div className='user-page-container'>
        <FormHeader
          onSearch={handleSearchFAQ}
          placeholder="FAQ検索"
          value={filterData.search}
          createTitle="FAQを追加"
          onChange={handleChangeSearch}
          onDelete={handleDeleteListItem}
          onAddItem={() => navigate('/faq/create')}
          isDisableButtonDelete={!selectedFAQs?.length}
        />
        <div>
          {isFetchingData
            ? <div className='loading-table'><Loading /></div>
            : <Table
              columns={columns}
              rowSelection={rowSelection}
              dataSource={listFAQs}
              pagination={{
                current: filterData?.page,
                showSizeChanger: true,
                pageSize: filterData?.perPage,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                total: totalFAQs
              }}
              loading={{ indicator: <Loading />, spinning: isFetchingData }}
              onChange={handleTableChange}
            />}
        </div>
        {isShowModalDelete && <ModalDelete
          title="削除確認"
          open={isShowModalDelete}
          onCancel={() => setIsShowModalDelete(false)}
          onClickConfirm={handleConfirmDeleteFAQ}
          isLoading={isSendingData}
        >
          本当に削除しますか。
        </ModalDelete>}
        {isShowModalDeleteList && <ModalDelete
          title="削除確認"
          open={isShowModalDeleteList}
          onCancel={() => setIsShowModalDeleteList(false)}
          onClickConfirm={handleClickConfirmDeleteList}
          isLoading={isSendingData}
        >
          本当に削除しますか。
        </ModalDelete>}
      </div>
    </div>
  )
    ;
};

export default FAQPage;
