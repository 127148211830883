import React from "react"
import PersonIcon from "assets/icons/person.svg"

const UserAvatar = (data) => {
  const realtimeStatus = data.realtimeStatus ? data.realtimeStatus : false
  const isClicked = data.isClicked ? data.isClicked : false
  const isMainChat = data.mainChat ? data.mainChat : false
  return (
    <div className={"user-avatar-container " +
      (isClicked ? 'user-avatar-container__white' : 'user-avatar-container__gray ') +
      (isMainChat ? 'user-avatar-container__main-chat' : '')}
    >
      <img src={PersonIcon} alt="person" />
      {realtimeStatus ?
        isMainChat ?
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.33341" cy="7.33332" r="6.16667" fill="#61BF87" stroke="white" />
          </svg> :
          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5.5" r="4.5" fill="#61BF87" stroke="white" />
          </svg>
        :
        isMainChat ?
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5.5" r="4.5" fill="#DCDCDF" stroke="white" />
          </svg>
          :
          <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5.5" r="4.5" fill="#DCDCDF" stroke="white" />
          </svg>
      }
    </div>
  )
}

export default UserAvatar;