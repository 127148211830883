import React, { useState, useEffect } from 'react';
import { Layout, Menu, Switch } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import logo from 'assets/images/logo.png';
import FAQIcon from 'assets/icons/sidebar-icons/faq-icon.svg'
import SymptomIcon from 'assets/icons/sidebar-icons/symptom-icon.svg'
import AccountIcon from 'assets/icons/sidebar-icons/account-icon.svg'
import SupportChatOfflineIcon from 'assets/icons/sidebar-icons/support-chat-offline-icon.svg'
import SupportChatOnlineIcon from 'assets/icons/sidebar-icons/chat-icon.svg'
import { setTitle } from 'redux/actions/title';
import { setOnlineStatus } from 'redux/actions/onlineStatus';
import { USER_TYPES } from 'common/constant';

const { Sider } = Layout;

const AppSidebar = (props) => {
  const [menu, setMenu] = useState([]);
  const [selectedKey, setSelectedKey] = useState('');
  const { role } = useSelector((state) => state.auth.authUser);
  const [collapsed, setCollapsed] = useState(false);
  const doctorOnlineStatus = useSelector((state) => state.onlineStatus.onlineStatus);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (item, title) => {
    navigate(item.key);
    dispatch(setTitle(title));
  }
  const toggleOnlineStatus = (checked) => {
    dispatch(setOnlineStatus(checked));
  };

  const menuDoctor = [
    {
      label: <div className='sidebar-chat-menu'>チャット<Switch size="small" defaultChecked={doctorOnlineStatus} onChange={toggleOnlineStatus} /></div>,
      key: 'chat',
      icon: <img src={doctorOnlineStatus ? SupportChatOnlineIcon : SupportChatOfflineIcon} alt="chat" />,
    },
  ];

  const menuAdmin = [
    {
      label: 'FAQ管理',
      key: 'faq',
      icon: <img src={FAQIcon} alt="FAQ" />,
    },
    {
      label: '症状管理',
      key: 'symptom',
      icon: <img src={SymptomIcon} alt="symptom-management" />,
    },
    {
      label: 'アカウント管理',
      key: 'account',
      icon: <img src={AccountIcon} alt="account-management" />,
    },
  ];

  useEffect(() => {
    let menu = [];
    switch (role) {
      case USER_TYPES.ADMIN:
        menu = menuAdmin;
        setSelectedKey('faq')
        break;
      case USER_TYPES.DOCTOR:
        menu = menuDoctor
        setSelectedKey('chat')
        break;
      default:
        menu = menuDoctor;
    }
    setMenu(menu);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role, doctorOnlineStatus]);

  useEffect(() => {
    const selectedMenuItem = menu.find(item => item.key === location.pathname.replace(/\//g, ""));
    if (selectedMenuItem) {
      if (role === USER_TYPES.DOCTOR) {
        dispatch(setTitle('チャット'));
      } else {
        dispatch(setTitle(selectedMenuItem.label));
      }
      setSelectedKey(selectedMenuItem.key);
    }
  }, [location, menu, dispatch, role]);

  return (
    <Sider className="app-sidebar" theme="light" collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
      <img width="90" height="90" src={logo} alt="logo" className="app-sidebar-logo" />
      <Menu mode="inline" className="sidebar-menu" selectedKeys={[selectedKey]} items={menu} onClick={(item) => {
        const title = menu.find((elm) => elm.key === item.key).label;
        handleClick(item, title)
      }} />
    </Sider>
  );
};

export default AppSidebar;
