import { Button } from 'antd';
import Loading from 'components/loading';
import React from 'react';

const ButtonWithLoading = ({ disabled, isLoading, children, ...rest}) => {
  return (
  <Button
    disabled={disabled || isLoading}
    className="button-with-loading"
    {...rest}
  >
    {isLoading &&<Loading/>}{children}
  </Button>
  )
}

export default ButtonWithLoading