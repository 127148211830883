import { ONLINE_STATUS } from 'redux/actions/types';

const INIT_STATE = {
  onlineStatus: true
};

const onlineStatusReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ONLINE_STATUS: {
      return { ...state, onlineStatus: action.payload.onlineStatus };
    }
    default: {
      return { ...state };
    }
  }
}

export default onlineStatusReducer;