import { TITLE } from 'redux/actions/types';

const INIT_STATE = {
    titleHeader: ''
};

const titleReducer = (state = INIT_STATE, action) => {
    switch (action.type) {
        case TITLE: {
            return { ...state, titleHeader: action.payload.title };
        }
        default: {
            return { ...state };
        }
    }
}

export default titleReducer;