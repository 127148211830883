import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router'
import { Table } from 'antd';
import AccountService from 'apis/AccountService';
import { handleSuccessFromServer } from 'utils/helpers';
import { handleErrorFromServer } from 'utils/errors';
import { PAGE_SIZE_DEFAULT, PAGE_SIZE_OPTIONS, USER_TEXT, USER_TYPES } from 'common/constant';
import FormHeader from 'components/formHeader';
import ModalDelete from 'components/modal/modalDelete';
import Loading from 'components/loading';
import EditIcon from 'assets/icons/edit.svg';
import DeleteIcon from 'assets/icons/delete.svg';

const INITIAL_SEARCH = {
  search: '',
  page: 1,
  perPage: PAGE_SIZE_DEFAULT
}

const AccountPage = () => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
    },
    {
      title: '氏名',
      dataIndex: 'fullName',
    },
    {
      title: '役割',
      dataIndex: 'role',
    },
    {
      title: '電話番号',
      dataIndex: 'phoneNumber',
    },
    {
      title: 'メールアドレス',
      dataIndex: 'email',
    },
    {
      title: '操作',
      dataIndex: 'manipulation',
      render: (_, record) => {
        return (
          <div className="table-action">
            <img src={EditIcon} alt="edit" onClick={() => handleEditAccount(record)} />
            <img src={DeleteIcon} alt="delete" onClick={() => handleDeleteAccount(record)} />
          </div>
        )
      }
    },
  ];
  const navigate = useNavigate();
  const [listAccounts, setlistAccounts] = useState([])
  const [totalAccounts, setTotalAccounts] = useState(0)
  const [isFetchingData, setIsFetchingData] = useState(false)
  const [filterData, setFilterData] = useState(INITIAL_SEARCH)
  const [deletingAccountId, setDeletingAccountId] = useState(null)
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [isShowModalDeleteItem, setIsShowModalDeleteItem] = useState(false)
  const [isShowModalDeleteList, setIsShowModalDeleteList] = useState(false)
  const [isSendingData, setIsSendingData] = useState(false)

  const queryData = async (filterData) => {
    const res = await AccountService.getAccounts(filterData)
    let accountData = res.data.map((item, index) => {
      if (item.role && item.role === USER_TYPES.ADMIN) {
        item = { ...item, role: USER_TEXT.ADMIN }
      } else {
        item = { ...item, role: USER_TEXT.DOCTOR }
      }
      return { ...item, key: index }
    })
    const _lastPage = res?.data?.lastPage ? res?.data?.lastPage : 1
    if (filterData.page > _lastPage) {
      setFilterData((filterData) => ({ ...filterData, page: _lastPage }))
      await AccountService.getAccounts(filterData).then((newResponse) => {
        accountData = newResponse.data.map((item, index) => ({ ...item, key: index }))
        setlistAccounts(accountData)
        setTotalAccounts(newResponse?.data?.total)
      })
    } else {
      setlistAccounts(accountData)
      setTotalAccounts(res?.data?.total)
    }
  }
  useEffect(() => {
    const getDataFirstLoad = async () => {
      setIsFetchingData(true)
      await queryData(INITIAL_SEARCH).then(
        setIsFetchingData(false)
      )
    }
    getDataFirstLoad()
  }, [])

  const onSelectChange = (selectedRowKeys, selectedRows, info) => {
    setSelectedAccounts(selectedRows.map((item) => item.id))
  }
  const rowSelection = {
    selectedAccounts,
    onChange: onSelectChange,
  }
  const handleSearchAccount = async () => {
    setFilterData((filterData) => ({ ...filterData, page: 1 }))
    queryData({ ...filterData, page: 1 })
  }
  const handleEditAccount = (record) => {
    return navigate(`/account/edit/${record.id}`)
  }
  const handleDeleteAccount = (record) => {
    setDeletingAccountId(record?.id)
    setIsShowModalDeleteItem(true)
  }
  const handleChangeSearch = (e) => setFilterData((filterData) => ({ ...filterData, search: e.target.value }))
  const handleDeleteListItem = () => {
    setIsShowModalDeleteList(true)
  }
  const handleTableChange = ({ current, pageSize }) => {
    setFilterData((filterData) => ({ ...filterData, page: current, perPage: pageSize }))
    queryData({ ...filterData, page: current, perPage: pageSize })
  }
  const handleConfirmDeleteAccount = async () => {
    setIsSendingData(true)
    try {
      const res = await AccountService.deleteAccount(deletingAccountId)
      handleSuccessFromServer(res)
      setIsShowModalDeleteItem(() => false)
      await queryData(filterData)
    } catch (error) {
      handleErrorFromServer(error)
    }
    setIsSendingData(false)
  }

  const handleClickConfirmDeleteList = async () => {
    setIsSendingData(true)
    try {
      const res = await Promise.all(selectedAccounts.map((item) => AccountService.deleteAccount(item)))
      handleSuccessFromServer(res[0])
      setIsShowModalDeleteList(false)
      setSelectedAccounts([])
      await queryData(filterData)
    } catch (error) {
      handleErrorFromServer(error)
    }
    setIsSendingData(false)
  }
  return (
    <div className='layout-container'>
      <div className='user-page-container'>
        <FormHeader
          onSearch={handleSearchAccount}
          placeholder="検索"
          value={filterData?.search}
          createTitle="アカウント発行"
          onChange={handleChangeSearch}
          onDelete={handleDeleteListItem}
          onAddItem={() => navigate('/account/create')}
          isDisableButtonDelete={!selectedAccounts?.length}
        />
        <div>
          {isFetchingData
            ? <div className='loading-table'><Loading /></div>
            : <Table
              columns={columns}
              rowSelection={rowSelection}
              dataSource={listAccounts}
              pagination={{
                current: filterData?.page,
                showSizeChanger: true,
                pageSize: filterData?.perPage,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                total: totalAccounts
              }}
              loading={{ indicator: <Loading />, spinning: isFetchingData }}
              onChange={handleTableChange}
            />}
        </div>
        {isShowModalDeleteItem && <ModalDelete
          title="削除確認"
          open={isShowModalDeleteItem}
          onCancel={() => setIsShowModalDeleteItem(false)}
          onClickConfirm={handleConfirmDeleteAccount}
          isLoading={isSendingData}
        >
          本当に削除しますか。
        </ModalDelete>}
        {isShowModalDeleteList && <ModalDelete
          title="削除確認"
          open={isShowModalDeleteList}
          onCancel={() => setIsShowModalDeleteList(false)}
          onClickConfirm={handleClickConfirmDeleteList}
          isLoading={isSendingData}
        >
          本当に削除しますか。
        </ModalDelete>}
      </div>
    </div>
  )
    ;
};

export default AccountPage;
