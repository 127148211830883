import {
    REFRESH_TOKEN,
    LOGIN,
    LOGOUT,
    CURRENT_USER
  } from "./types";
import AuthService from "apis/AuthService";

export const login = (body) => (dispatch) =>{
    return AuthService.login(body).then(
        (data) => {
            dispatch({
                type: LOGIN,
                payload: { user: data.data },
            });
            return Promise.resolve(data);
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}

export const getAuthUser = () => (dispatch) =>{
    return AuthService.getAuthUser().then(
        (data) => {
            dispatch({
                type: CURRENT_USER,
                payload: { user: data },
            });
            return Promise.resolve(data);
        },
        (error) => {
            return Promise.reject(error);
        }
    );
}

export const logout = () => (dispatch) => {
    return AuthService.logout().then(
        (data) => {
            dispatch({
                type: LOGOUT,
            });
            return Promise.resolve(data);
        },
        (error) => {
            dispatch({
                type: LOGOUT,
            });
            return Promise.reject(error);
        }
    )
}
export const refreshToken = (accessToken) => (dispatch) => {
    dispatch({
      type: REFRESH_TOKEN,
      payload: accessToken,
    })
}