import axios from 'configs/api';

const getAllUser = async (value) => {
  try {
    const { data } = await axios.get('cms/clients', { params: value });
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

const getHistory = async (userId, value) => {
  try {
    const { data } = await axios.get(`cms/clients/${userId}`, { params: value });
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

const getRecommendation = async (userId) => {
  try {
    const { data } = await axios.get(`cms/clients/recommendation/${userId}`);
    return data.data;
  } catch (err) {
    console.log(err);
  }
};

const ChatService = {
  getHistory,
  getAllUser,
  getRecommendation,
};

export default ChatService;
