export const USER_TYPES = {
  ADMIN: 1,
  DOCTOR: 2,
}

export const USER_TEXT = {
  ADMIN: '管理者',
  DOCTOR: 'アドバイザー'
}

export const PAGE_SIZE_OPTIONS = ["10", "20", "50", "100"]
export const PAGE_SIZE_DEFAULT = 10
export const MAX_LENGTH_DISPLAY_TEXT = 20

export const API_URL = process.env.REACT_APP_API_URL;

