import axios from "configs/api";
import TokenService from "apis/TokenService";

const setup = (store) => {
  axios.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token && config.url !== "cms/admins/refreshToken") {
        config.headers["authToken"] = token;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      const refreshToken = TokenService.getLocalRefreshToken();
      const errInfor = err?.response?.data;
      const errMessagesInfor = ['jwt expired', 'jwt must be provided', 'Unauthorized!. User not found'];
      if ([400].includes(err.response.status) && errInfor.message === 'jwt must be provided') {
        if (!window.location.pathname.includes("signin")) window.location.href = "/signin";
        return Promise.reject(err);
      }
      if ([400, 401].includes(err.response.status) && originalConfig.url.includes("refreshToken")) {
        if (!window.location.pathname.includes("signin") && errMessagesInfor.includes(errInfor.message)) window.location.href = "/signin";
        return Promise.reject(err);
      }
      // Access Token was expired
      if (([401].includes(err.response.status) || (errInfor.statusCode === 400 && errInfor.message === 'jwt expired')) && refreshToken) {
        try {
          const rs = await axios.get("/cms/admins/refreshToken", {
            headers: { authToken: refreshToken },
          });

          const { authToken } = rs.data;

          TokenService.updateLocalAccessToken(authToken);

          return axios(originalConfig);
        } catch (_error) {
          console.log(_error);
          return Promise.reject(_error);
        }
      }

      return Promise.reject(err);
    }
  );
};

export default setup;
