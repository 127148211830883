import { Button, Input } from "antd";
import React from "react";

const FormHeader = ({
  title,
  onSearch,
  onAddItem,
  onDelete,
  createTitle,
  onClickApply,
  isDisableButtonDelete = false,
  isShowButtonDelete = true,
  isDisableButtonApply = false,
  isShowButtonApply = true,
  ...rest
}) => {
  return (
    <div className="header-user-page">
      <div className="header-user-page__content">
        <div className="header-user-page__content--left-btns">
          <Input {...rest} />
          <Button
            className="header-user-page__content--search-btn"
            onClick={onSearch}
            type="primary"
          >
            検索
          </Button>
          {createTitle && (
            <Button
              className="header-user-page__content--search-btn"
              onClick={onAddItem}
              type="secondary"
            >
              {createTitle}
            </Button>
          )}
          {isShowButtonDelete && (
            <Button
              className="header-user-page__content--delete"
              type="primary"
              onClick={onDelete}
              disabled={isDisableButtonDelete}
            >
              削除
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormHeader;
