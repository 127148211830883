import { Layout, Modal, Switch, message } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import iconUser from "assets/icons/user.svg";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "redux/actions/auth";
import { USER_TYPES } from "common/constant";
import { handleErrorFromServer } from "utils/errors";
import AccountService from "apis/AccountService";
import { setOnlineStatus } from 'redux/actions/onlineStatus';

const { Header } = Layout;

const AppHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModalUser = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleLogout = () => {
    dispatch(setOnlineStatus(false));
    dispatch(logout())
      .then((res) => {})
      .catch((err) => {
        if (err.status !== 400) {
          handleErrorFromServer(err);
        }
      })
      .finally(() => {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        if (role !== USER_TYPES.ADMIN) {
          localStorage.removeItem("clientId");
          localStorage.removeItem("accessTokenClient");
          navigate("/signin");
        } else navigate("/signin");
      });
  };

  const toggleMailReceiving = async (checked) => {
    try {
      await AccountService.updateNotification({
        isNotification: Number(checked),
      }).then(() => {
        message.success("success");
      });
    } catch (error) {
      handleErrorFromServer(error);
    }
  };

  const authUser = useSelector((state) => state.auth.authUser);
  const role = authUser?.role;
  const mailReceiving = authUser?.isNotification;
  const location = useLocation();
  const allowShowBackButtonRoutes = [
    "/account/edit",
    "/account/create",
    "faq/edit",
    "faq/create",
  ];
  let isShowBackButton = false;
  allowShowBackButtonRoutes.forEach((route) => {
    if (location.pathname.includes(route)) {
      isShowBackButton = true;
    }
  });

  const pageTitle = useSelector((state) => state.title.titleHeader);

  return (
    <Header className="app-header">
      <div
        className="title text-white"
        style={{ display: "flex", alignItem: "center" }}
      >
        {isShowBackButton && (
          <svg
            onClick={() => navigate(-1)}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.0608 5.00011L4.81079 11.2501H21.7501V12.7501H4.81076L11.0608 19.0001L10.0001 20.0608L1.93945 12.0001L10.0001 3.93945L11.0608 5.00011Z"
              fill="white"
            />
          </svg>
        )}
        <span style={{ marginLeft: "8px" }}>{pageTitle}</span>
      </div>
      <div
        onClick={showModalUser}
        className="row-inline pointer header-subtitle"
      >
        <img
          src={
            authUser.avatar
              ? process.env.REACT_APP_API_URL + authUser.avatar
              : iconUser
          }
          alt="user"
        />
        <span className="text-large ml-2">{authUser?.fullName}</span>
      </div>
      <Modal
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        wrapClassName="header-modal"
        closable={false}
      >
        {/* <div className="mt-2" style={{ display: "flex", alignItems: "center" }}>
          <div style={{ width: "30px" }}>
            <Switch
              size="small"
              defaultChecked={mailReceiving}
              onChange={toggleMailReceiving}
            />
          </div>
          <span className="text-normal ml-2">メールで通知</span>
        </div> */}
        <div
          onClick={handleLogout}
          className="mt-2"
          style={{ display: "flex", alignItems: "center" }}
        >
          <div style={{ width: "30px", textAlign: "center" }}>
            <LogoutOutlined />
          </div>
          <span className="text-normal ml-2">ログアウト</span>
        </div>
      </Modal>
    </Header>
  );
};

export default AppHeader;
