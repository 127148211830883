import React, { useEffect, useRef, useState } from "react";
import { Table, Input, message } from "antd";
import SymptomService from "apis/SymptomService";
import { handleSuccessFromServer } from "utils/helpers";
import { handleErrorFromServer } from "utils/errors";
import {
  PAGE_SIZE_DEFAULT,
  PAGE_SIZE_OPTIONS,
  USER_TEXT,
  USER_TYPES,
} from "common/constant";
import FormHeader from "components/formHeader";
import ModalDelete from "components/modal/modalDelete";
import Loading from "components/loading";
import EditIcon from "assets/icons/edit.svg";
import DeleteIcon from "assets/icons/delete.svg";
import SaveIcon from "assets/icons/save.svg";
import CancelIcon from "assets/icons/cancel.svg";

const INITIAL_SEARCH = {
  search: "",
  page: 1,
  perPage: PAGE_SIZE_DEFAULT,
};

const SymptomPage = () => {
  const columns = [
    {
      title: "症状名",
      dataIndex: "name",
      editable: true,
      width: 500,
    },
    {
      title: "操作",
      dataIndex: "manipulation",
      render: (_, record) => {
        if (editingRecord && editingRecord.key === record.key) {
          return (
            <div className="table-action">
              <img
                src={SaveIcon}
                alt="edit"
                onClick={() => handleSaveSymptom()}
              />
              <img
                src={CancelIcon}
                alt="edit"
                onClick={() => handleCancelEditSymptom(record)}
              />
            </div>
          );
        } else {
          return (
            <div className="table-action">
              <img
                src={EditIcon}
                alt="edit"
                onClick={() => handleEditSymptom(record)}
              />
              {!editingRecord.key && <img
                src={DeleteIcon}
                alt="delete"
                onClick={() => handleDeleteSymptom(record)}
              />}
            </div>
          );
        }
      },
    },
  ];

  const [listSymptoms, setlistSymptoms] = useState([]);
  const [totalSymptoms, setTotalSymptoms] = useState(0);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [filterData, setFilterData] = useState(INITIAL_SEARCH);
  const [deletingSymptomId, setDeletingSymptomId] = useState(null);
  const [selectedSymptoms, setSelectedSymptoms] = useState([]);
  const [isShowModalDeleteItem, setIsShowModalDeleteItem] = useState(false);
  const [isShowModalDeleteList, setIsShowModalDeleteList] = useState(false);
  const [isSendingData, setIsSendingData] = useState(false);
  const [editingRecord, setEditingRecord] = useState({});

  let updatingSymptomName = "";

  const isEditing = (record) => record.key === editingRecord.key;
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputRef = useRef();

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }, [inputRef]);

    return (
      <td {...restProps}>
        {editing ? (
          <Input
            ref={inputRef}
            onChange={(e) => (updatingSymptomName = e.target.value)}
            defaultValue={editingRecord ? editingRecord.name : ""}
          />
        ) : (
          children
        )}
      </td>
    );
  };

  const queryData = async (filterData) => {
    const res = await SymptomService.getSymptoms(filterData);
    let symptomData = res.data.map((item, index) => {
      if (item.role && item.role === USER_TYPES.ADMIN) {
        item = { ...item, role: USER_TEXT.ADMIN };
      } else {
        item = { ...item, role: USER_TEXT.DOCTOR };
      }
      return { ...item, key: index, isAdding: false };
    });
    const _lastPage = res?.data?.lastPage ? res?.data?.lastPage : 1;
    if (filterData.page > _lastPage) {
      setFilterData((filterData) => ({ ...filterData, page: _lastPage }));
      await SymptomService.getSymptoms(filterData).then((newResponse) => {
        symptomData = newResponse.data.map((item, index) => ({
          ...item,
          key: index,
          isAdding: false,
        }));
        setlistSymptoms(symptomData);
        setTotalSymptoms(newResponse?.data?.total);
      });
    } else {
      setlistSymptoms(symptomData);
      setTotalSymptoms(res?.data?.total);
    }
  };
  useEffect(() => {
    const getDataFirstLoad = async () => {
      setIsFetchingData(true);
      await queryData(INITIAL_SEARCH).then(setIsFetchingData(false));
    };
    getDataFirstLoad();
  }, []);

  const onSelectChange = (selectedRowKeys, selectedRows, info) => {
    setSelectedSymptoms(selectedRows.map((item) => item.id));
  };
  const rowSelection = {
    selectedSymptoms,
    onChange: onSelectChange,
  };
  const handleSearchSymptom = async () => {
    setFilterData((filterData) => ({ ...filterData, page: 1 }));
    queryData({ ...filterData, page: 1 });
  };
  const handleAddNewSymptom = (record) => {
    const newSymptom = {
      name: "",
      key: listSymptoms.length,
      isAdding: true,
    };
    setlistSymptoms([newSymptom, ...listSymptoms]);
    handleEditSymptom(newSymptom);
  };
  const handleSaveSymptom = async () => {
    try {
      const data = {
        name: updatingSymptomName
      }
      if (editingRecord.id) {
        await SymptomService.updateSymptom(editingRecord.id, data).then(() => {
          message.success("success");
          queryData({ ...filterData, page: 1 });
          setEditingRecord({});
        });
      } else {
        await SymptomService.createSymptom(data).then(() => {
          message.success("success");
          queryData({ ...filterData, page: 1 });
          setEditingRecord({});
        });
      }
    } catch (error) {
      handleErrorFromServer(error);
    }
  };
  const handleEditSymptom = (record) => {
    editingRecord.key === record.key
      ? setEditingRecord({})
      : setEditingRecord(record);
  };
  const handleCancelEditSymptom = (record) => {
    setEditingRecord({})
    if (record.isAdding) {
      const newListSymptom = listSymptoms.filter((symptom) => {
        return symptom?.key !== record.key;
      });
      setlistSymptoms(newListSymptom);
    }
  }
  const handleDeleteSymptom = (record) => {
    if (!record.isAdding) {
      setDeletingSymptomId(record?.id);
      setIsShowModalDeleteItem(true);
    } else {
      const newListSymptom = listSymptoms.filter((symptom) => {
        return symptom?.key !== record.key;
      });
      setlistSymptoms(newListSymptom);
    }
  };
  const handleChangeSearch = (e) =>
    setFilterData((filterData) => ({ ...filterData, search: e.target.value }));
  const handleDeleteListItem = () => {
    setIsShowModalDeleteList(true);
  };
  const handleTableChange = ({ current, pageSize }) => {
    setFilterData((filterData) => ({
      ...filterData,
      page: current,
      perPage: pageSize,
    }));
    queryData({ ...filterData, page: current, perPage: pageSize });
  };
  const handleConfirmDeleteSymptom = async () => {
    setIsSendingData(true);
    if (deletingSymptomId) {
      try {
        const res = await SymptomService.deleteSymptom(deletingSymptomId);
        handleSuccessFromServer(res);
        setIsShowModalDeleteItem(() => false);
        await queryData(filterData);
      } catch (error) {
        handleErrorFromServer(error);
      }
    } else {
      setIsShowModalDeleteItem(() => false);
    }
    setIsSendingData(false);
  };

  const handleClickConfirmDeleteList = async () => {
    setIsSendingData(true);
    try {
      const res = await Promise.all(
        selectedSymptoms.map((item) => {
          if (item) {
            SymptomService.deleteSymptom(item);
          }
          return true;
        })
      );
      handleSuccessFromServer(res[0]);
      setIsShowModalDeleteList(false);
      setSelectedSymptoms([]);
      await queryData(filterData);
    } catch (error) {
      handleErrorFromServer(error);
    }
    setIsSendingData(false);
  };
  return (
    <div className="layout-container">
      <div className="user-page-container">
        <FormHeader
          onSearch={handleSearchSymptom}
          placeholder="症状検索"
          value={filterData?.search}
          createTitle="症状を追加"
          onChange={handleChangeSearch}
          onDelete={handleDeleteListItem}
          onAddItem={handleAddNewSymptom}
          isDisableButtonDelete={!selectedSymptoms?.length}
        />
        <div>
          {isFetchingData ? (
            <div className="loading-table">
              <Loading />
            </div>
          ) : (
            <Table
              columns={mergedColumns}
              components={{
                body: {
                  cell: EditableCell,
                },
              }}
              rowClassName="editable-row"
              rowSelection={rowSelection}
              dataSource={listSymptoms}
              pagination={{
                current: filterData?.page,
                showSizeChanger: true,
                pageSize: filterData?.perPage,
                pageSizeOptions: PAGE_SIZE_OPTIONS,
                total: totalSymptoms,
              }}
              loading={{ indicator: <Loading />, spinning: isFetchingData }}
              onChange={handleTableChange}
            />
          )}
        </div>
        {isShowModalDeleteItem && (
          <ModalDelete
            title="削除確認"
            open={isShowModalDeleteItem}
            onCancel={() => setIsShowModalDeleteItem(false)}
            onClickConfirm={handleConfirmDeleteSymptom}
            isLoading={isSendingData}
          >
            本当に削除しますか。
          </ModalDelete>
        )}
        {isShowModalDeleteList && (
          <ModalDelete
            title="削除確認"
            open={isShowModalDeleteList}
            onCancel={() => setIsShowModalDeleteList(false)}
            onClickConfirm={handleClickConfirmDeleteList}
            isLoading={isSendingData}
          >
            本当に削除しますか。
          </ModalDelete>
        )}
      </div>
    </div>
  );
};

export default SymptomPage;
